import React from "react";

export const JoinNewsLetterButton = () => (
  <div>
    <div className="bg-blue-100 lg:py-5 rounded">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-center">
        <h2 className="text-2xl text-bold tracking-tight text-gray-700 text-center">
          <span className="block">Always know what I'm up to!</span>
        </h2>
        <div className="lg:mt-0 lg:flex-shrink-0 mx-12 mt-8">
          <div className="rounded-md text-center">
            <a
              href="https://newsletter.waxcarvers.com"
              aria-label="Join newsletter"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent
                         text-base font-medium rounded-md text-white bg-blue-700 hover:bg-indigo-700"
            >
              Get weekly emails!
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
