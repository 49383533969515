import React from 'react'

export const Left = ({ children, img, noPattern }) =>
  <>
    <div className="relative mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <div className="mt-3 text-lg leading-7 text-gray-700 whitespace-pre-wrap">
          {children}
        </div>
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0">
        {img}
      </div>
    </div>
    {noPattern ? ''
    : <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404"
         height="784" fill="none" viewBox="0 0 404 784">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20"
                 patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="484" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
    </svg>}
  </>

export const Right = ({ children, img, noMargin }) =>
  <div className={`relative ${noMargin ? '' : 'mt-12 sm:mt-16 lg:mt-24'}`}>
    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="lg:col-start-2">
        <div className="mt-3 text-lg leading-7 text-gray-700 whitespace-pre-wrap">
          {children}
        </div>
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
        {img}
      </div>
    </div>
  </div>
